<template>
    <Authenticator>
        <div id="app">
            <div
                v-show="isTPPWidgetVisible"
                id="tppWidget"
                class="absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex justify-center items-center z-75"
            ></div>
            <div class="flex h-full">
                <SideMenu :routes="menuRoutes" />
                <ContentArea
                    class="flex-grow"
                    content-class=""
                >
                    <template v-slot:header>
                        <TopHeader :routes="menuRoutes" />
                    </template>

                    <template v-slot:default>
                        <router-view />
                    </template>

                    <template v-slot:footer>
                        <BottomNavigation
                            :nav-routes="bottomNavRoutes"
                            :action-routes="actionRoutes"
                        />
                    </template>
                </ContentArea>
            </div>
            <SlideOver
                v-if="$route.meta.parent"
                :is-page="true"
                @close="$router.replace({ name: $route.meta.parent })"
            >
                <router-view name="slideover"></router-view>
            </SlideOver>
            <div id="slideovers"></div>
            <Confirmation />
            <Alerts />
            <ws-connector />
        </div>
    </Authenticator>
</template>

<script>
import SideMenu from '@/components/root/SideMenu';
import TopHeader from '@/components/root/TopHeader';
import BottomNavigation from '@/components/root/BottomNavigation';
import Authenticator from '@/components/auth/Authenticator';
import ContentArea from '@/components/ui/ContentArea';
import SlideOver from '@/components/root/SlideOver';
import Confirmation from '@/components/root/Confirmation';
import Alerts from '@/components/root/Alerts';
import WsConnector from '@/components/wst/WSConnector';
import MessagingCounterMixin from '@/mixins/MessagingCounterMixin';
import AppsPermissionsFetcher from '@/mixins/AppsPermissionsFetcher';
import PaymentsMixin from '@/mixins/PaymentsMixin';
import { mapGetters } from 'vuex';

const MENU_ROUTES = [
    'dashboard.index',
    // 'payments.create',
    'payments.index',
    'serviceRequests.index',
    'messages.index',
    'rentableItems.index',
    'profile.index',
    // 'unit.index',
    // 'renewal.index',
    'application_service.index',
    'application_service.guarantor_contract',
    'profile.logout',
];

const BOTTOM_NAV_ROUTES = ['dashboard.index', 'payments.index', 'serviceRequests.index', 'messages.index'];

const ACTION_ROUTES = ['payments.create', 'serviceRequests.create'];

export default {
    components: {
        WsConnector,
        Alerts,
        Confirmation,
        SlideOver,
        ContentArea,
        Authenticator,
        SideMenu,
        BottomNavigation,
        TopHeader,
    },

    mixins: [MessagingCounterMixin, AppsPermissionsFetcher, PaymentsMixin],

    computed: {
        ...mapGetters({
            isTPPWidgetVisible: 'accounting/isTPPWidgetVisible',
        }),

        menuRoutes() {
            return this.collectRoutes(MENU_ROUTES);
        },

        bottomNavRoutes() {
            return this.collectRoutes(BOTTOM_NAV_ROUTES);
        },

        actionRoutes() {
            return this.collectRoutes(ACTION_ROUTES);
        },
    },

    methods: {
        collectRoutes(names) {
            return names
                .map(name => {
                    const route = this.availableRoutes.find(route => route.name === name);
                    if (route) {
                        return {
                            name: route.name,
                            ...route.meta,
                        };
                    }
                })
                .filter(r => r);
        },
    },
};
</script>

<style>
#app {
    @apply h-full;
}

#tppWidget {
    top: 51%;
}
</style>
