const totals = state => state.totals;
const chargeTotals = state => state.chargeTotals;
const isNegativeBalance = state => state.chargeTotals?.balance < 0;
const isTPPWidgetVisible = state => state.isTPPWidgetVisible;

export default {
    totals,
    chargeTotals,
    isNegativeBalance,
    isTPPWidgetVisible,
};
