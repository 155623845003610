import moment from 'moment';

export const currentDate = moment(new Date()).format('MM/DD/YYYY');

export const getStartAndEndDateMonth = month => {
    return {
        startDate: month.startOf('month').format('YYYY-MM-DD'),
        endDate: month.endOf('month').format('YYYY-MM-DD'),
    };
};

export const dateFormat = 'MM/DD/YYYY';

export const periodFormat = 'YYYY-MM';

export const dateFormatter = value => (value ? moment(value).format(dateFormat) : '');

export const getPeriodDate = ({ month, period }) => `${month} ${moment(period).year()}`;

export const getFirstAndLastDatesMonth = ({ month, format = 'YYYY-MM-DD' }) => {
    return {
        startDate: moment(month).startOf('month').format(format),
        endDate: moment(month).endOf('month').format(format),
    };
};
