import NotifyMixin from '@/mixins/NotifyMixin';
import AccountingQueryManager from '@/api/accounting/accountingQueryManager';
import { periodFormat } from '@/utils/Date';
import { monthsPeriod } from '@/components/dashboard/constants.js';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';

export default {
    mixins: [NotifyMixin, AccountingQueryManager],

    watch: {
        currentProfile: {
            immediate: true,
            async handler(val) {
                if (val) {
                    await this.getTotals();
                    if (this.availableRoutes) {
                        this.updateAvailableRoutes();
                    }
                }
            },
        },
    },

    computed: {
        ...mapGetters({
            totals: 'accounting/totals',
        }),

        periodsForTotals() {
            const endDate = moment().format(periodFormat);
            const startDate = moment(endDate)
                .subtract(monthsPeriod - 1, 'months')
                .format(periodFormat);
            return {
                endDate,
                startDate,
            };
        },
    },

    methods: {
        ...mapActions({
            setTotals: 'accounting/setTotals',
        }),

        async getTotals() {
            const { startDate, endDate } = this.periodsForTotals;
            this.isLoading = true;
            try {
                const totals = await this.queryHandler('getTotals', 'resident', {
                    accountId: this.currentProfile.occupancyId,
                    startDate,
                    endDate,
                });
                this.setTotals(totals?.reverse());
                if (!totals.length) {
                    this.$router.replace({ name: 'application_service.index' });
                }
            } catch (error) {
                this.notifyError(error.message);
            } finally {
                this.isLoading = false;
            }
        },
    },
};
