import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            currentProfile: 'auth/currentProfile',
        }),
    },
    methods: {
        async queryHandler(method, endpoint, data, provider) {
            const queryProvider = this[provider] || this.$accountingServiceDataProvider;
            return await queryProvider[method](endpoint, {
                headers: {
                    customer_id: this.currentProfile.customerId,
                    customerId: this.currentProfile.customerId,
                    community_id: this.currentProfile.communityId,
                    communityId: this.currentProfile.communityId,
                },
                ...data,
            });
        },
    },
};
